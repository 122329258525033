import { Link } from 'gatsby';
import React from 'react';
import { Layout } from '../../../components/Layout';
import BlogCategories from '../../../components/BlogCategories';

const RealPropertyReportsCompliance = () => (
  <Layout
    title="Articles about surveying and RPR (Real Property Report) requirements relating to municipal bylaws permits and related issues."
    description="Want to learn more about the bylaws in Calgary, Red Deer and Edmonton and how they effect an RPR / Real Property Reports? This a in depth blog style resource to help you understand the details of the many permit and by-law issues."
    keywords=""
  >
    <main>
      <BlogCategories />

      <h1>Category: Real Property Reports (RPRs) & Compliance</h1>
      <ul>
        {/*<li>*/}
        {/*  <Link to="/articles/real-property-reports-compliance">*/}
        {/*    Deck Permit Requirements in Calgary*/}
        {/*  </Link>*/}
        {/*  <br />*/}
        {/*  Very few home improvements make such a decisive impact than outside decking. Apart from*/}
        {/*  the instant visual appeal, adding a deck to your home is a wonderful way to expand your*/}
        {/*  living space. Before you start heading out for lumber and sketching designs, there is one*/}
        {/*  important step that shouldn't be overlooked: securing a Calgary deck permit.*/}
        {/*</li>*/}
        <li>
          <Link to="/articles/land-surveyors-and-rprs">
            Land Surveyors and RPRs - What are they? An Outsider's Perspective
          </Link>
          <br />
          Discover the vital role of land surveyors and Real Property Reports (RPRs) in the dynamic
          world of real estate and construction. Gain insights into the precision and expertise
          behind property boundaries, mapping, and urban planning. Explore the hidden realm of
          measurements and boundary markings that shape our communities.
        </li>
        <li>
          <Link to="/articles/calgary-rpr-window-wells-air-conditioning">
            Calgary Real Property Reports, Window Wells & Air Conditioning Units: What You Need To
            Know
          </Link>
          <br />
          The City of Calgary updated Land Use Byload 1P2007 in 2008 introducing significant changes
          regarding window wells and air conditioning units, affecting real estate transactions and
          homeowners. It is crucial for buyers and sellers to understand the implications of these
          changes.
        </li>
      </ul>
    </main>
  </Layout>
);

export default RealPropertyReportsCompliance;
